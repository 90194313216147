import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/CompfestTalksPage/Header"
import Speakers from "../components/CompfestTalksPage/CompfestTalksSpeakers"
import Rundown from "../components/CompfestTalksPage/Rundown"
import Share from "../components/CompfestTalksPage/Share"
import SponsorMedpar from "../components/SponsorMedpar"

const Webinar = () => (
  <Layout>
    <div style={{ overflowX: "hidden", width: "100%", height: "100%" }}>
      <SEO title="COMPFEST Talks" />
      <div className="layout">
        <Header />
        <Speakers />
        <Rundown />
        <Share />
        <SponsorMedpar />
      </div>
    </div>
  </Layout>
)

export default Webinar
