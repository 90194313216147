import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Rundown from "./rundown"
import SmallerNav from "./smallerNav"

const Styles = styled.div`
  h2 {
    font-family: itc;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin-bottom: 2.5rem;
  }
  margin-bottom: 12.5rem;
  @media (max-width: 576px) {
    margin-bottom: 6rem;
  }
`
export default function Index() {
  const { day1, day2 } = useStaticQuery(graphql`
    {
      day1: allContentfulCompfestTalksRundown(
        filter: { day: { day: { eq: "Day 1" } } }
        sort: { order: ASC, fields: time }
      ) {
        nodes {
          name
          time
          channel
        }
      }
      day2: allContentfulCompfestTalksRundown(
        filter: { day: { day: { eq: "Day 2" } } }
        sort: { order: ASC, fields: time }
      ) {
        nodes {
          name
          time
          channel
        }
      }
    }
  `)
  const days = [
    {
      title: "Day 1",
      component: <Rundown data={day1} />,
    },
    {
      title: "Day 2",
      component: <Rundown data={day2} />,
    },
  ]
  return (
    <Styles>
      <h2>EVENT RUNDOWN</h2>
      <SmallerNav navItems={days} />
    </Styles>
  )
}
