import React, { useState } from "react"
import styled from "styled-components"
// import axios from "axios"

// import { useSelector } from "react-redux"
// import { getAuthUser } from "../../../redux/slices/session/selectors"
// import { toastActions } from "../../../redux/toast"
// import constants from "../../../api/constant"

import Button from "../../Button"
import Image from "../../image"
// import Countdown from "../../Countdown/LandingPage"

import freeAdmissionSvg from "./free-admission.svg"
import titleSvg from "./compfest-talks-title.svg"
import RegisterPopup from "./register-popup"

const HeaderStyle = styled.div`
  display: flex;
  flex-direction: column;
  aling-items: center;
  text-align: center;

  img {
    margin: 0 auto;

    &:first-child {
      margin: 96px auto 10px;
    }
    &.big-title {
      width: 100%;
      max-width: 610px;
    }
  }

  p.date,
  p.tagline {
    font-family: itc;
    font-weight: bold;
    font-size: 1.5rem;
  }
  p.date {
    margin: 24px 0 48px;
  }
  p.coming-soon {
    font-weight: bold;
    margin-bottom: 8px;
  }
  p.desc {
    margin-top: 85px;
  }
  p.tagline {
    margin: 12px 0 36px;
  }

  #bubble-left,
  #bubble-right {
    position: absolute;
    opacity: 0.5;
  }
  button {
    font-weight: 700;
  }

  #bubble-left {
    width: 400px;
    transform: translateX(-50%);
    top: -72px;
    left: calc(50% - 576px);
  }
  #bubble-right {
    width: 300px;
    transform: translateX(50%);
    right: calc(50% - 500px);
    top: -72px;
  }

  @media (max-width: 768px) {
    img:first-child {
      margin-top: 48px;
      width: 100%;
      max-width: 192px;
    }
    img.big-title {
      max-width: 343px;
    }
    p.tagline {
      margin-bottom: 32px;
    }

    #bubble-left {
      width: 180px;
      transform: translateX(-50%) rotateZ(-30deg);
      top: -152px;
      left: calc(50% - 124px);
    }
    #bubble-right {
      width: 148px;
      transform: translateX(50%);
      right: calc(50% - 167px);
      top: 42px;
    }
  }
`

// const START_DATE = new Date(2020, 9, 4, 10, 0, 0) // 3 October
// const CURRENT_DATE = new Date()
// const REGISTER_BACKEND_ID = 91
// const BASE_URL = `${constants.API_BASE_URL}/registrations`
// const REGISTER_URL = `${BASE_URL}/events/${REGISTER_BACKEND_ID}/teams/anon/`

export default () => {
  const [showRegisterPopup, setShowRegisterPopup] = useState(false)
  // const [isLoading, setLoading] = useState(false)
  // const userData = useSelector(getAuthUser)

  // const register = () => {
  //   if (isLoading) {
  //     return
  //   }

  //   setLoading(true)
  //   if (!userData) {
  //     setLoading(false)
  //     toastActions.showToast("You need to log in first!")
  //   } else {
  //     axios
  //       .post(REGISTER_URL, {
  //         email: userData.email,
  //         additional: "[]",
  //       })
  //       .then((resp) => {
  //         if (resp.data.message === "Create new registrar") {
  //           setShowRegisterPopup(true)
  //         } else if (resp.data.message === "Duplicate anon registrar") {
  //           toastActions.showToast("COMPFEST Talks already registered")
  //         } else {
  //           // TODO angga is working on a redix fix
  //           // throw new Error("Failed")
  //           setShowRegisterPopup(true)
  //         }
  //       })
  //       .catch(() => {
  //         toastActions.showToast("Failed registering COMPFEST Talks")
  //       })
  //       .finally(() => {
  //         setLoading(false)
  //       })
  //   }
  // }

  return (
    <HeaderStyle>
      <img src={freeAdmissionSvg} alt="FREE ADMISSION" />
      <div style={{ position: "relative", zIndex: -1 }}>
        <div id="bubble-left">
          <Image imgName="bubble-left.png" />
        </div>
        <div id="bubble-right">
          <Image imgName="bubble-right.png" />
        </div>
      </div>
      <img className="big-title" src={titleSvg} alt="COMPFEST TALKS" />
      <p className="date">3 - 4 October 2020</p>
      {/* {CURRENT_DATE < START_DATE && (
        <>
          <p className="coming-soon">Coming soon in</p>
          <Countdown date={START_DATE} compfestTalks />
        </>
      )} */}
      <p className="desc">
        Numerous <b>virtual talk shows</b> on certain topics related to tech,
        takes on the theme
      </p>
      <p className="tagline">
        “Empowering Society through Technological Innovation”
      </p>
      <Button to="/compfesttalks/channel">WATCH NOW</Button>
      {showRegisterPopup && (
        <RegisterPopup onReturn={() => setShowRegisterPopup(false)} />
      )}
    </HeaderStyle>
  )
}
