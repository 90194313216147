import React from "react"
import styled from "styled-components"

import Button from "../../Button"

const RegisterPopupStyle = styled.div`
  position: fixed;
  z-index: 201;
  width: calc(100% - 40px);
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.background};
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    margin: 0 auto 16px;
    background: linear-gradient(
      90deg,
      #02dbfc 0%,
      #00fdd2 34.98%,
      #006ce1 64.97%,
      #ce0040 100%
    );
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    display: inline-block;
    font-size: 48px;
    @media (max-width: 576px) {
      font-size: 36px;
    }
  }

  p.see-u {
    font-weight: bold;
    margin: 48px 0 8px;
  }
  p.regist-date {
    font-family: itc;
    font-weight: bold;
    font-size: 1.5rem;
    color: #ffcb15;
    margin-bottom: 64px;
  }
`

export default ({ onReturn }) => (
  <RegisterPopupStyle>
    <h1>THANK YOU FOR REGISTERING</h1>
    <p>
      You will be informed of further updates and reminders through your email
    </p>
    <p className="see-u">See you on</p>
    <p className="regist-date">3 - 4 Oktober 2020</p>
    <Button onClick={onReturn}>Return to Home</Button>
  </RegisterPopupStyle>
)
