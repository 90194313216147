import React, { useState } from "react"
import styled from "styled-components"

const Style = styled.div`
  .small-nav {
    margin-top: 30px;
    padding-bottom: 10px;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    overflow-x: auto;
    color: white;

    div {
      display: block;
      margin: 0 2.5rem;
      cursor: pointer;
      white-space: nowrap;

      &:first-child {
        margin-left: 0 !important;
      }
      &:last-child {
        margin-right: 0 !important;
      }
    }

    div a {
      color: white;
      text-decoration: none;
    }
  }

  @media (max-width: 992px) {
    .small-nav {
      display: flex;
      justify-content: center;
    }
  }
`

export default function smallerNav({ navItems, linearColor }) {
  const [index, setIndex] = useState(0)
  const style = {
    opacity: ".5",
  }
  const mainColor = linearColor
    ? linearColor
    : `linear-gradient(90deg, #02DBFC 0%, #00FDD2 100%)`
  const activeStyle = {
    opacity: "1",
    fontWeight: "bold",
    background: mainColor,
    WebkitBackgroundClip: "text",
    backgroundClip: "text",
    WebkitTextFillColor: "transparent",
    color: "transparent",
    paddingBottom: "4px",
    borderBottom: "1px solid",
    borderImageSource: mainColor,
    WebkitBorderImage: mainColor,
    borderImageSlice: "1",
  }
  return (
    <Style>
      <div className="small-nav">
        {navItems.map((navItem, idx) => (
          <div
            key={idx}
            onClick={() => setIndex(idx)}
            style={index === idx ? activeStyle : style}
          >
            {navItem.title}
          </div>
        ))}
      </div>
      {navItems[index].component && (
        <div className="nav-content">{navItems[index].component}</div>
      )}
    </Style>
  )
}
