import React from "react"
import styled from "styled-components"

import SmallerNav from "../Rundown/smallerNav"
import Image from "../../image"

const Styles = styled.div`
  padding-top: 50px;
  padding-bottom: 100px;

  h2 {
    text-align: center;
  }

  .desktop {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    img {
      border-radius: 10px;
    }

    .image-small {
      width: 44%;
      padding: 1%;
    }

    .image-medium {
      width: 90%;
      padding: 1%;
    }

    .image-big {
      width: 90%;
      padding: 1%;
    }
  }

  .mobile {
    display: none;

    img {
      border-radius: 10px;
    }
  }

  @media screen and (max-width: 575px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: block;

      & > * {
        padding: 10px;
      }
    }
  }
`

const hideThis = false

const speakersDesktopDay1 = [
  {
    imgName: "compfest-talks-speakers-1.png",
    size: "image-small",
  },
  {
    imgName: "compfest-talks-speakers-2.png",
    size: "image-medium",
  },
  {
    imgName: "compfest-talks-speakers-3.png",
    size: "image-medium",
  },
  {
    imgName: "compfest-talks-speakers-4.png",
    size: "image-medium",
  },
  {
    imgName: "compfest-talks-speakers-5.png",
    size: "image-big",
  },
  {
    imgName: "compfest-talks-speakers-6.png",
    size: "image-medium",
  },
  {
    imgName: "compfest-talks-speakers-7.png",
    size: "image-medium",
  },
  {
    imgName: "compfest-talks-speakers-8.png",
    size: "image-medium",
  },
  {
    imgName: "compfest-talks-speakers-9.png",
    size: "image-medium",
  },
  {
    imgName: "compfest-talks-speakers-10.png",
    size: "image-medium",
  },
]

const speakersMobileDay1 = [
  {
    imgName: "compfest-talks-speakers-mobile-1.png",
  },
  {
    imgName: "compfest-talks-speakers-mobile-2.png",
  },
  {
    imgName: "compfest-talks-speakers-mobile-3.png",
  },
  {
    imgName: "compfest-talks-speakers-mobile-4.png",
  },
  {
    imgName: "compfest-talks-speakers-mobile-5.png",
  },
  {
    imgName: "compfest-talks-speakers-mobile-6.png",
  },
  {
    imgName: "compfest-talks-speakers-mobile-7.png",
  },
  {
    imgName: "compfest-talks-speakers-mobile-8.png",
  },
  {
    imgName: "compfest-talks-speakers-mobile-9.png",
  },
  {
    imgName: "compfest-talks-speakers-mobile-10.png",
  },
]

const speakersDesktopDay2 = [
  {
    imgName: "compfest-talks-speakers-d2-1.png",
    size: "image-small",
  },
  {
    imgName: "compfest-talks-speakers-d2-2.png",
    size: "image-small",
  },
  {
    imgName: "compfest-talks-speakers-d2-3.png",
    size: "image-small",
  },
  {
    imgName: "compfest-talks-speakers-d2-4.png",
    size: "image-small",
  },
  {
    imgName: "compfest-talks-speakers-d2-5.png",
    size: "image-small",
  },
  {
    imgName: "compfest-talks-speakers-d2-6.png",
    size: "image-small",
  },
  {
    imgName: "compfest-talks-speakers-d2-7.png",
    size: "image-medium",
  },
  {
    imgName: "compfest-talks-speakers-d2-8.png",
    size: "image-medium",
  },
  {
    imgName: "compfest-talks-speakers-d2-9.png",
    size: "image-medium",
  },
  {
    imgName: "compfest-talks-speakers-d2-10.png",
    size: "image-medium",
  },
  {
    imgName: "compfest-talks-speakers-d2-11.png",
    size: "image-medium",
  },
  {
    imgName: "compfest-talks-speakers-d2-12.png",
    size: "image-medium",
  },
]

const speakersMobileDay2 = [
  {
    imgName: "compfest-talks-speakers-mobile-d2-1.png",
  },
  {
    imgName: "compfest-talks-speakers-mobile-d2-2.png",
  },
  {
    imgName: "compfest-talks-speakers-mobile-d2-3.png",
  },
  {
    imgName: "compfest-talks-speakers-mobile-d2-4.png",
  },
  {
    imgName: "compfest-talks-speakers-mobile-d2-5.png",
  },
  {
    imgName: "compfest-talks-speakers-mobile-d2-6.png",
  },
  {
    imgName: "compfest-talks-speakers-mobile-d2-7.png",
  },
  {
    imgName: "compfest-talks-speakers-mobile-d2-8.png",
  },
  {
    imgName: "compfest-talks-speakers-mobile-d2-9.png",
  },
  {
    imgName: "compfest-talks-speakers-mobile-d2-10.png",
  },
  {
    imgName: "compfest-talks-speakers-mobile-d2-11.png",
  },
  {
    imgName: "compfest-talks-speakers-mobile-d2-12.png",
  },
]

const days = [
  {
    title: "Day 1",
    component: (
      <>
        <div className="desktop">
          {speakersDesktopDay1.map(({ imgName, size }, index) => (
            <div className={size} key={index}>
              <Image imgName={imgName} alt={`Speaker ${index}`} />
            </div>
          ))}
        </div>
        <div className="mobile">
          {speakersMobileDay1.map(({ imgName }, index) => (
            <div key={index}>
              <Image imgName={imgName} alt={`Speaker ${index}`} />
            </div>
          ))}
        </div>
      </>
    ),
  },
  {
    title: "Day 2",
    component: (
      <>
        <div className="desktop">
          {speakersDesktopDay2.map(({ imgName, size }, index) => (
            <div className={size} key={index}>
              <Image imgName={imgName} alt={`Speaker ${index}`} />
            </div>
          ))}
        </div>
        <div className="mobile">
          {speakersMobileDay2.map(({ imgName }, index) => (
            <div key={index}>
              <Image imgName={imgName} alt={`Speaker ${index}`} />
            </div>
          ))}
        </div>
      </>
    ),
  },
]

export default function CompfestTalksSpeakers() {
  if (hideThis) return <div style={{ height: "50px" }} />
  return (
    <Styles>
      <h2>SPEAKERS AND TOPICS</h2>
      <SmallerNav navItems={days} />
    </Styles>
  )
}
